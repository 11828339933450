const getters = {
  storewise_details: (state) => state.storewise_details,
  storeadreslocation_details: (state) => state.storeadreslocation_details,
  location_details: (state) => state.location_details,
  login: (state) => state.login,
  logout: (state) => state.logout,
  register: (state) => state.register,
  profiledetails: (state) => state.profiledetails,
  customermanageprofile: (state) => state.customermanageprofile,
  resetpassword: (state) => state.resetpassword,
  verifyotp: (state) => state.verifyotp,
  customerregstrn: (state) => state.customerregstrn,
  customeraddress: (state) => state.customeraddress,
  states: (state) => state.states,
  cities: (state) => state.cities,
  arealist: (state) => state.arealist,
  managecustomeraddrs: (state) => state.managecustomeraddrs,
  deletecustomeraddrs: (state) => state.deletecustomeraddrs,
  khatacustomer: (state) => state.getcustomerkhatam,
  khatadetail: (state) => state.getcustomerkhatadetail,
  custmrorder: (state) => state.getcustomerorderlist,
  orderdetail: (state) => state.getcustomerorderdetail,
  latestorderdetail: (state) => state.getcustomerlatestorder,
  cancellationreasons: (state) => state.getordercancellationreasons,
  ordercancellation: (state) => state.customerordercancellation,
  custnotifictn: (state) => state.getcustomernotification,
  custreadnotfnt: (state) => state.getcustreadnotifictn,
  custadvnceserch: (state) => state.getadvanceserchlist,
  custfiltrlist: (state) => state.getadvancefiltrlist,
  custadvncenewserch: (state) => state.getadvancenewserchlist,
  custstrepagedetl: (state) => state.getstrepagedetail,
  custdetailcategry: (state) => state.getdetailcategory,
  custdetailitem: (state) => state.getshopitemdetail,
  custsubctshp: (state) => state.getcatsubcatgryshp,
  custongnofr: (state) => state.getshopcatgryoffrs,
  custshpitmserch: (state) => state.getshopsearchitemdetail,
  custproddetail: (state) => state.getproductdetail,
  custrecomitems: (state) => state.getrecommendeditems,
  custaddtocart: (state) => state.addtocartproduct,
  custusedchoicesaddons: (state) => state.usedchoicesaddons,
  addtocart: (state) => state.addtocart,
  custcartdetail: (state) => state.getcartdetail,
  custcartempty: (state) => state.getemptycart,
  custcatwisedetl: (state) => state.getcategrywisestore,
  custcatwisesearch: (state) => state.getcategrywisesearch,
  custremveitm: (state) => state.getremoveitemfrmcart,
  custglblsetng: (state) => state.getglobalsetng,
  custplceordr: (state) => state.getcustorderplaced,
  custvalcoupon: (state) => state.getcustvalidatecoupon,
  custplcedelvryordr: (state) => state.gethatdelvrycustorderplaced,
  custviewkhata: (state) => state.getcustkhatavieworder,
  custfavrtstr: (state) => state.getstorefavorite,
  notifymecust: (state) => state.getcustnotifyme,
  storebusinesshours: (state) => state.getstorebusinesshours,
  custdeliverycharges: (state) => state.getcustdeliverycharges,
  custaddreview: (state) => state.customeraddreview,
  custwishlist: (state) => state.customerwishlist,
  custwishlistdetails: (state) => state.customerwishlistdetails,
  custaddtocartfromwishlist: (state) => state.customeraddtocartfromwishlist,
  custmanagewishlist: (state) => state.customermanagewishlist,
  custdeletewishlist: (state) => state.customerdeletewishlist,
  recentstoreview: (state) => state.recentstoreview,
  updatefreshchat: (state) => state.updatefreshchat,
  checkusertoken: (state) => state.checkusertoken,
  searchautocomplete: (state) => state.searchautocomplete,
  savecards: (state) => state.savecards,
  deletecards: (state) => state.deletecards,
  custaccountstatus: (state) => state.custaccountstatus,
  getStoreExclusiveOffer: (state) => state.getStoreExclusiveOffer,
  getstorelist: (state) => state.getstorelist,
  verifyEmailAddress:(state) => state.verifyEmailAddress,
  emailvarificationotp:(state) => state.emailvarificationotp,
  paymentforcustomer:(state) => state.paymentforcustomer,
};

export default {
  ...getters,
};
