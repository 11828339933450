import axios from "axios";
import Vue from "vue";
import VueCookies from "vue-cookies";
import store from './store.js'
import { bus } from "../main";
import firebase from 'firebase/app';

Vue.use(VueCookies);
let bannerbody = {
  user_id: "0",
  lat: "",
  long: "",
  is_demo_app: "",
  pincode:"",
  area_id:"",
  is_mobile:0
};

let sendenquiry = {
  user_id:'',
  name:'',
  phone:'',
  email:'',
  inquiry_type:'',
  order_id:'',
  subject:'',
  message:''
};

let loginbody = {
  phone: "",
  otp: "",
  role_id: "4",
  source: "web",
  fcm_token: "",
  device_os: "web",
};

let giftvoucher = {
  user_id: "",
  name: "",
  phone: "",
  amount: "",
  from_date: "",
  to_date: "",
  card_name: "",
  card_number: "",
  expiration_date: "",
  card_code: "",
  source: '1',
  billing_address_id:'',
  transaction_id:''
};

let logoutbody = {
  phone: "",
  role_id: "4",
  source: "web",
  fcm_token:""
};

let registerbody = {
  phone: "",
  role_id: "4",
  source: "web",
};

let profiledetailbody = {
  user_id: "",
};

let areabody = {
  cityid: "",
};

// let customerprofile={
//   user_id:'',
//   name:'',
//   phone:'',
//   email:'',
//   password:'',
//   confirm_password:'',

//   country_id:'',
//   state_id:'',
//   city_id:'',
//   area_id:'',

// };
let resetpasswrdbody = {
  phone: "",
  source: "web",
  role_id: "4",
  login: "1",
};

let verfiotpbody = {
  phone: "",
  otp: "",
  role_id: "4",
};

let customerbody = {
  user_id: "",
};

let statebody = {
  country: "",
};

let citybody = {
  state: "",
};

// let custmraddres = {
//   customer_address_id: "",
//   user_id: "",
//   alias: "",
//   alias_code: "",
//   address1: "",
//   address2: "",
//   country_id: "",
//   state_id: "",
//   city_id: "",
//   area_id: "",
//   zipcode: "",
//   is_default: "",
//   latitude:"",
//   longitude:"",
//   state:"",
//   city:"",
//   country:"",
//   //is_deleted:'0'
// };

let custmraddres = {
  user_id: "",
  customer_address_id: "",
  alias: "",
  alias_code: "",
  address1: "",
  address2: "",
  zipcode1: "",
  is_default: "",
  latitude:"",
  longitude:"",
  remarks:"",
  country:"",
  state:"",
  city:""
};

let custmradrsdelte = {
  customer_address_id: "",
  user_id: "",
  is_deleted: "",
};

let khatabody = {
  user_id: "",
};

let khatadetailbody = {
  user_id: "",
};

let customerorderbody = {
  user_id: "",
  search_query:"",
  page:1,
  limit:1
};

let orderdetailbody = {
  order_id: "",
};

let latestorderbody = {
  user_id: "",
};

let ordercancelbody = {
  user_id: "",
  order_id: "",
  reason_id: "",
};

let custnotifctnbody = {
  user_id: "",
};

let custwalletbody = {
  user_id: "",
  page:1
};

let custwallettransfer = {
  user_id: "",
  to_user_id:"",
  amount:1
};

let custwalletcredit = {
  user_id: "",
  amount:0,
  transaction_id:"",
};

let customerbymobile = {
  phone:""
};

let custreadnotifctn = {
  user_id: "",
  notification_id: "",
  is_read: "",
};
let custadvncesearch = {
  user_id: "",
  search_query: "",
  lat: "",
  long: "",
};

let custadvncenwsearch = {
  user_id: "",
  search_query: "",
  store_type: "",
  delivery_type: "",
  online_order: "",
  lat: "",
  long: "",
};

let custdetailcategry = {
  store_id: "",
};

let custitemdetail = {
  store_id: "",
  category_id: "",
  user_id: "",
  sub_category_id: "",
  page: "",
  item_type: "",
  min_price: "",
  max_price: "",
  min_discount: "",
  max_discount: "",
  brands: "",
  item_tags: "",
  prescription: "",
  out_of_stock: "",
};
let custshopsearch = {
  store_id: "",
  user_id: "",
  search_query: "",
  page: "",
  item_type: "",
  min_price: "",
  max_price: "",
  min_discount: "",
  max_discount: "",
  brands: "",
  item_tags: "",
  prescription: "",
  out_of_stock: "",
  categories:"",
  item_collection_id:""
};

let custprodctdetail = {
  item_id: "",
  user_id: "",
};

let custrecomitems = {
  item_id: "",
  user_id: "",
};
//  search_query:''
let custshopcatgryoffrs = {
  user_id: "",
  store_id: "",
  is_store_listing: "",
};
let custcatsubcatgry = {
  store_id: "",
  category_id: "",
};

let addcartdetail = {
  user_id: "",
  store_id: "",
  item_id: "",
  item_variants_id: "",
  quantity: "",
  cart_type: "",
  choices: "",
  addons: "",
  combo_items: "",
  add_new: "",
  cart_id: "",
};

let usedchoicesaddons = {
  user_id: "",
  store_id: "",
  item_variants_id: "",
};

let addtocart = {
  user_id: "",
  store_id: "",
  items: [],
};

let custcartdetail = {
  user_id: "",
  cart_type: "",
  timezone: "+6",
};

let custcartremve = {
  user_id: "",
  cart_type: "",
};

let custcatwsestre = {
  user_id: "",
  category_id: "",
  lat: "",
  long: "",
  store_type: "",
  delivery_type: "",
  online_order: "",
};

let custcatwsesrch = {
  user_id: "",
  shop_category_id: "",
  cuisine_id: "",
  lat: "",
  long: "",
  store_type: "",
  delivery_type: "",
  online_order: "",
  is_favourite:"",
  area_id:"",
  pincode:""
};

let custcartremoveitm = {
  user_id: "",
  store_id: "",
  item_id: "",
  item_variants_id: "",
  cart_id: "",
};

let custordrplace = {
  user_id: "",
  store_id: "",
  date: "",
  items: "",
  amount: "",
  remarks: "",
  order_type: "",
  payment_method_id: "",
  is_scheduled: "",
  // delivery_charges:'',
  // delivery_slot_from:'',
  // delivery_slot_to:'',
};

let validatecoupon = {
  user_id: "",
  store_id: "",
  discount_code: "",
  amount: "",
  order_type: "",
  payment_method: "",
  delivery_slot:""
};
let createcustomerchat = {
  user_id:"",
  order_id:""
};
// let custordrdelvryplace = {
//   user_id: "",
//   store_id: "",
//   date: "",
//   items: "",
//   amount: "",
//   remarks: "",
//   order_type: "",
//   payment_method_id: "",
//   is_scheduled: "",
//   delivery_charges: "",
//   delivery_slot_from: "",
//   delivery_slot_to: "",
//   scheduled_delivery_date: "",
//   offer_code: '',
//   offer_discount: '',
//   transaction_id:"",
//   packaging_cost:"",
//   order_tax:"",
//   source:"",
//   pay_thru_wallet:"",
//   wallet_amount:"",
//   pickup_slot_from:"",
//   pickup_slot_to:""
// };

let generatetransactionid = {
  user_id: "",
  store_id: "",
  amount: "",
  payment_method_id: "",
  source:1,
  call_back_url:''
};

let generatetransactionrequest = {
  user_id: "",
  payment_gateway_type: "",
  amount: "",
  call_back_url: "",
  source:1,
  card_number:'',
  expiration_date:'',
  card_code:'',
  card_name:'',
  billing_address_id:''
};

let custmrreordrdelvry = {
  store_id: "",
  order_id: "",
  user_id: "",
  cart_type: "1",
  remove_cart: "0",
};

let khataviewdetail = {
  user_id: "",
  order_id: "",
};

let favrtebody = {
  user_id: "",
  store_id: "",
  is_favourite: "",
};

let custnotifymebody = {
  user_id: "",
  item_id: "",
};

let storebusinesshoursbody = {
  store_id: "",
};

let custdeliverychargesbody = {
  user_id: "",
  store_id: "",
  order_amount: "",
  lat: "",
  long: "",
};

let addreview = {
  user_id: "",
  order_id: "",
  store_review: "",
  item_review: "",
  delivery_review: "",
};

let wishlist = {
  user_id: "",
  page:1
};

let deletewishlist = {
  user_id: "",
  wishlist_id:""
};

let recentstoreview = {
  user_id: "",
  lat:"",
  long:""
};

let addtocartwishlist = {
  user_id: "",
  wishlist_id:""
};

let getwishlist = {
  wishlist_id:""
};

let managewishlist = {
  user_id:"",
  store_id:"",
  wishlist_name:"",
  reminder_type:0,
  dayofweek:0,
  items:[],
  daily_time:"",
  monthly_date:""
}

let freshchat = {
  user_id:"",
  freshchat_restore_id:"",
}

let checkuser = {
  user_id:"",
  token:"",
}

let search_autocomplete = {
  search_query: "",
  store_id:"",
  limit:1,
  page:1
};

let save_cards = {
  user_id:"",
  page:1
}

let delete_cards = {
  customer_card_id:"",
  user_id:""
}
let customer_account = {
  user_id:""
}
let store_exclusive_offer = {
  store_id : "",
  is_active : "",
};
let verify_email = {
  user_id : "",
  source : "",
};
let verify_otp = {
  user_id : "",
  otp : "",
}
let payment_customer = {
  order_id: "",
  user_id: "",
  card_number: "",
  expiration_date: "",
  card_code: "",
  card_name: "",
}

//  custcartdetail.user_id=data.user_id
//  custcartdetail.cart_type=data.cart_type
//  custcartdetail.timezone="+6"
let langcode = "en";
//let appversion="2.0.9";
//export const appversion = "2.0.1";
//export const appversion = "2.3.0";
export const appversion = "2.7.0";
let apptoken = localStorage.getItem("token") != "" ? localStorage.getItem("token") : "f6e8ff2b56b00618907db48219ab38ef";
//let appdevice="80c8e0a6a3d753f0";
// let headerpart =[{
//   headers: {
//     'Language-Code':"en",
//     'App-Version':"2.0.0",
//     'token':"f6e8ff2b56b00618907db48219ab38ef",
//     'device-id':"80c8e0a6a3d753f0"
//     }
// }]
// const mainURL = "https://api.gharbazaar.com/v240/"; // live URL
// const mainURL = "https://api.gharbazaar.com/v220/";
// const mainURL = "https://apidemo.gharbazaar.com/v220/";
const mainURL = "https://api.gharbazaar.com/v250/"; // new live URL
//const mainURL = "http://14.99.98.243:8081/GB-WebService/"; // local url of mehul
//const mainURL = "https://apitest.gharbazaar.com/v230/"
// const mainURL =  "http://192.168.1.60/GB-WebService/"  // local url of Rudresh

const actions = {
  async getIpLocation({ commit }) {
    return axios.get("https://api.ipdata.co/?api-key=test").then((response) => {
      commit("STOREADRESLOCATION_DETAILS", response);
      return response;
    });
  },
  async getLocationDetails({ commit }) {
    return axios.get(`http://ipinfo.io/?format=json`).then((response) => {
      commit("LOCATION_DETAILS", response.data.data.data);
    });
  },
  async sendEnquiry({ commit }, data) {
    sendenquiry.user_id = data.user_id;
    sendenquiry.inquiry_type = data.inquiry_type;
    sendenquiry.name = data.name;
    sendenquiry.phone = data.phone;
    sendenquiry.email = data.email;
    sendenquiry.order_id = data.order_id;
    sendenquiry.subject = data.subject;
    sendenquiry.message = data.message;
    return axios
      .post(`${mainURL}customer/send_inquiry`, sendenquiry, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
          //'device-id':appdevice
        },
      })
      .then((response) => {
        commit("STOREWISE_DETAILS", response);
        return response;
      })
      .catch((error) => {
        alert(error);
      });
  },  
  async getBanners({ commit }, data) {
    bannerbody.user_id = data.user_id;
    bannerbody.lat = data.lat;
    bannerbody.long = data.long;
    bannerbody.is_demo_app = data.is_demo_app;
    bannerbody.pincode = data.pincode;
    bannerbody.area_id = data.area_id;
    bannerbody.is_mobile = data.is_mobile;
    return axios
      .post(`${mainURL}customer/city_wise_dashboard`, bannerbody, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
          //'device-id':appdevice
        },
      })
      .then((response) => {
        commit("STOREWISE_DETAILS", response);
        return response;
      })
      .catch((error) => {
        alert(error);
      });
  },
  async login({ commit }, data) {
    //loginbody = data
    // let formData = new FormData();
    // formData.append("phone", data.phone);
    // formData.append("otp", data.otp);
    // formData.append("role_id", '4');
    // formData.append("source", 'web');
    // formData.append("fcm_token", 'dsdfsdfd');
    // formData.append('device_os','web')
    loginbody.phone = data.phone;
    loginbody.otp = data.otp;
    loginbody.role_id = "4";
    loginbody.source = "web";
    loginbody.fcm_token = data.fcm_token;
    loginbody.device_os = "web";
    return axios
      .post(`${mainURL}user/login`, loginbody, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
          //'device-id':appdevice
        },
      })
      .then((response) => {
        commit("LOGIN", response);
        if(response.data.status == 1){
          localStorage.setItem("user", JSON.stringify(response.data.data.profile));
          localStorage.setItem("token", response.data.token);
        }
        return response.data;
      })
      .catch((error) => {
        alert(error + "error to getting data");
      });
  },
  async logout({ commit }, data) {
    logoutbody.phone = data.phone;
    // loginbody.password=data.password;
    logoutbody.role_id = "4";
    logoutbody.source = "web";
    logoutbody.fcm_token = data.fcm_token;

    return axios
      .post(`${mainURL}user/logout`, logoutbody, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
          //'device-id':appdevice
        },
      })
      .then((response) => {
        commit("LOGOUT", response);
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        return response.data;
      })
      .catch((error) => {
        alert(error + "error to getting data");
      });
  },
  async register({ commit }, data) {
    registerbody.role_id = "4";
    registerbody.source = "web";
    registerbody.phone = data.phone;
    return axios
      .post(`${mainURL}customer/customer_verification`, registerbody, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
          //'device-id':appdevice
        },
      })
      .then((response) => {
        commit("REGISTER", response);
        return response.data;
      });
  },
  async profiledetails({ commit }, data) {
    profiledetailbody.user_id = data.user_id;

    return axios
      .post(`${mainURL}user/profile_details`, profiledetailbody, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
          //'device-id':appdevice
        },
      })
      .then((response) => {
        commit("PROFILEDETAILS", response);
        return response.data;
      });
  },
  async customermanageprofile({ commit }, data) {    
    let formData = new FormData();
    formData.append("user_id", data.user_id);
    formData.append("name", data.name);
    formData.append("phone", data.phone);
    formData.append("email", data.email);
    // formData.append("password", data.password);
    // formData.append("confirm_password", data.confirmpassword);
    formData.append("user_picture", data.user_picture);
    formData.append("country_id", 231);
    formData.append("state_id", 0);
    formData.append("city_id", 0);
    formData.append("area_id", 0);
    formData.append("is_image_delete", data.is_image_delete);

    return axios
      .post(`${mainURL}user/manage_profile`, formData, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
          //'device-id':appdevice
        },
      })
      .then((response) => {
        commit("CUSTOMERMANAGEPROFILE", response);
        return response.data;
      });
  },

  async resetpassword({ commit }, data) {
    // resetpasswrdbody
    resetpasswrdbody.phone = data.phone;
    resetpasswrdbody.source = "web";
    resetpasswrdbody.role_id = "4";
    resetpasswrdbody.is_login = "1";

    return axios
      .post(`${mainURL}user/reset_password`, resetpasswrdbody, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
          //'device-id':appdevice
        },
      })
      .then((response) => {
        commit("RESETPASSWORD", response);
        return response.data;
      });
  },
  async verficationotp({ commit }, data) {
    verfiotpbody.phone = data.phone;
    verfiotpbody.otp = data.otp;
    verfiotpbody.role_id = "4";
    return axios
      .post(`${mainURL}user/verification_otp`, verfiotpbody, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
          //'device-id':appdevice
        },
      })
      .then((response) => {
        commit("VERIFYOTP", response);
        return response.data;
      });
  },
  async signup({ commit }, data) {
    let formData = new FormData();
    formData.append("phone", data.phone);
    formData.append("name", data.name);
    formData.append("email", data.email);
    //formData.append("password", data.password);
    formData.append("user_picture", data.user_picture);
    formData.append("address", data.address);
    formData.append("address2", data.address2);
    formData.append("is_confirm", "1");
    formData.append("role_id", "4");
    // formData.append("country_id", "231");
    // formData.append("state_id", data.state_id);
    // formData.append("city_id", data.city_id);
    // formData.append("area_id", data.area_id);
    formData.append("country", data.country);
    formData.append("state", data.state);
    formData.append("city", data.city);
    formData.append("street", data.street);
    formData.append("alias", data.alias);
    formData.append("alias_code", data.alias_code);
    formData.append("latitude", data.latitude);
    formData.append("longitude", data.longitude);
    // formData.append("zipcode", data.pincode);
    formData.append("zipcode1", data.zipcode1);
    formData.append("fcm_token", data.fcm_token);
    formData.append("device_os", "web");
    // signupbody.phone=data.phone
    // signupbody.name=data.name
    // signupbody.email=data.email
    // signupbody.password=data.password
    // signupbody.user_picture=data.user_picture
    // signupbody.address=data.address
    // signupbody.address2=data.address2
    // signupbody.is_confirm='1'
    // signupbody.role_id='4'
    // signupbody.country_id='101'
    // signupbody.state_id='0'
    // signupbody.city_id='0'
    // signupbody.area_id='0'
    return axios
      .post(`${mainURL}customer/customer_registration`, formData, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
          //'Content-Type': 'multipart/form-data'
          //  'token':apptoken,
          //'device-id':appdevice
        },
      })
      .then((response) => {
        commit("CUSTOMERREGSTRN", response);
        if (response.data.status == "1") {
          localStorage.setItem("user", JSON.stringify(response.data.data.profile));
          return response.data;
        } else {
          return response.data;
        }
      });
  },
  async customeraddress({ commit }, data) {
    customerbody.user_id = data.user_id;

    return axios
      .post(`${mainURL}customer/customer_addresses`, customerbody, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTOMERADDRESS", response);
        return response.data;
      });
  },
  async getcountries({commit}, data){
    statebody.country = data;
    return axios
      .get(`${mainURL}user/countries_list?countries=`+ statebody.country,{
        headers:{
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        }
      })
      .then((response) => {
        commit("COUNTRIES", response)
        return response.data;
      })
  },
  async getstates({ commit }, data) {
    statebody.country = data.country;
    return axios
      .get(`${mainURL}user/states_list?` + statebody.country, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("STATES", response);
        return response.data;
      });
  },
  async getcities({ commit }, data) {
    citybody.state = data.state;
    //citybody.state=data.state;
    return axios
      .get(`${mainURL}user/cities_list?` + citybody.state, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CITIES", response);
        return response.data;
      });
  },
  async getarealist({ commit }, data) {
    areabody.city = data.city;
    return axios
      .get(`${mainURL}user/areas_list?` + areabody.city, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("AREALIST", response);
        return response.data;
      });
  },
  async managecustomeraddrs({ commit }, data) {
    custmraddres.user_id = data.user_id;
    custmraddres.customer_address_id = data.customer_address_id;
    custmraddres.alias = data.alias;
    custmraddres.alias_code = data.alias_code;
    custmraddres.address1 = data.address1;
    custmraddres.address2 = data.address2;
    // custmraddres.country_id = data.country_id;
    // custmraddres.state_id = data.state_id;
    // custmraddres.city_id = data.city_id;
    // custmraddres.area_id = data.area_id;
    custmraddres.zipcode1 = data.zipcode1;
    // custmraddres.zipcode = data.zipcode;
    custmraddres.is_default = data.is_default;
    custmraddres.latitude = data.latitude;
    custmraddres.longitude = data.longitude;
    custmraddres.remarks = data.remarks;
    custmraddres.street = data.street;
    //  custmraddres.is_deleted=data.is_deleted
    custmraddres.country = data.country;
    custmraddres.state = data.state;
    custmraddres.city = data.city;
    return axios
      .post(`${mainURL}customer/manage_customer_addresses`, custmraddres, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("MANAGECUSTOMERADRES", response);
        return response.data;
      });
  },
  async deletecustomeraddrs({ commit }, data) {
    custmradrsdelte.customer_address_id = data.customer_address_id;
    custmradrsdelte.user_id = data.user_id;
    custmradrsdelte.is_deleted = data.is_deleted;
    custmradrsdelte.alias = data.alias;
    custmradrsdelte.alias_code = data.alias_code;
    custmradrsdelte.address1 = data.address1;
    custmradrsdelte.address2 = data.address2;
    custmradrsdelte.zipcode1 = data.zipcode1;
    custmradrsdelte.latitude = data.latitude;
    custmradrsdelte.longitude = data.longitude;
    custmradrsdelte.remarks = data.remarks;
    custmradrsdelte.country = data.country;
    custmradrsdelte.state = data.state;
    custmradrsdelte.city = data.city;
    return axios
      .post(`${mainURL}customer/manage_customer_addresses`, custmradrsdelte, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("DELETECUSTOMERADRES", response);
        return response.data;
      });
  },
  async getcustomerkhata({ commit }, data) {
    khatabody.user_id = data.user_id;
    return axios
      .post(`${mainURL}customer/customer_dashboard`, khatabody, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("KHATACUSTOMER", response);
        return response.data;
      });
  },
  async getcustomerkhatadetail({ commit }, data) {
    khatadetailbody.user_id = data.user_id;
    khatadetailbody.store_id = data.store_id;
    return axios
      .post(`${mainURL}customer/customer_account_details`, khatadetailbody, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("KHATADETAIL", response);
        return response.data;
      });
  },
  async getcustomerorderlist({ commit }, data) {
    customerorderbody.user_id = data.user_id;
    customerorderbody.search_query = data.search_query;
    customerorderbody.page = data.page;
    customerorderbody.limit = data.limit;
    return axios
      .post(`${mainURL}customer/orders_list`, customerorderbody, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTMRORDER", response);
        return response.data;
      });
  },
  async getcustomerorderdetail({ commit }, data) {
    orderdetailbody.order_id = data.order_id;
    return axios
      .post(`${mainURL}customer/order_details`, orderdetailbody, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("ORDERDETAIL", response);
        return response.data;
      });
  },
  async getcustomerlatestorder({ commit }, data) {
    latestorderbody.user_id = data.user_id;
    return axios
      .post(`${mainURL}customer/get_latest_order`, latestorderbody, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("LATESTORDERDETAIL", response);
        return response.data;
      });
  },
  async getordercancellationreasons({ commit }) {
    return axios
      .get(`${mainURL}customer/order_cancellation_reasons`, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CANCELLATIONREASONS", response);
        return response.data;
      });
  },
  async customerordercancellation({ commit }, data) {
    ordercancelbody.user_id = data.user_id;
    ordercancelbody.order_id = data.order_id;
    ordercancelbody.reason_id = data.reason_id;
    ordercancelbody.source=1;
    return axios
      .post(`${mainURL}customer/order_cancellation`, ordercancelbody, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("ORDERCANCELLATION", response);
        return response.data;
      });
  },
  async getcustomernotification({ commit }, data) {
    custnotifctnbody.user_id = data.user_id;
    return axios
      .post(`${mainURL}customer/notifications_list`, custnotifctnbody, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSNOTIFICTN", response);
        return response.data;
      });
  },
  async getcustomerwallet({ commit }, data) {
    custwalletbody.user_id = data.user_id;
    custwalletbody.page = data.page;
    return axios
      .post(`${mainURL}customer/my_wallet`, custwalletbody, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSNOTIFICTN", response);
        return response.data;
      });
  },
  async customerwallettransfer({ commit }, data) {
    custwallettransfer.user_id = data.user_id;
    custwallettransfer.to_user_id = data.to_user_id,
    custwallettransfer.amount = data.amount;

    return axios
      .post(`${mainURL}customer/transfer_wallet`, custwallettransfer, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSNOTIFICTN", response);
        return response.data;
      });
  },
  async customerwalletcredit({ commit }, data) {

    custwalletcredit.user_id = data.user_id;
    custwalletcredit.amount = data.amount;
    custwalletcredit.transaction_id = data.transaction_id;

    return axios
      .post(`${mainURL}customer/credit_wallet`, custwalletcredit, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSNOTIFICTN", response);
        return response.data;
      });
  },
  async managegiftvoucher({ commit }, data) {
    giftvoucher.user_id = data.user_id;
    giftvoucher.name = data.name;
    giftvoucher.phone = data.phone;
    giftvoucher.amount = data.amount;
    giftvoucher.from_date = data.from_date;
    giftvoucher.to_date = data.to_date;
    giftvoucher.card_name = data.card_name;
    giftvoucher.card_number = data.card_number;
    giftvoucher.expiration_date = data.expiration_date;
    giftvoucher.card_code = data.card_code;
    giftvoucher.source = data.source;
    giftvoucher.billing_address_id = data.billing_address_id;
    giftvoucher.transaction_id = data.transaction_id;
    
    custmraddres.customer_address_id = data.customer_address_id;
    return axios
      .post(`${mainURL}customer/manage_gift_voucher`, giftvoucher, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("MANAGEGIFTVOUCHER", response);
        return response.data;
      });
  },
  async getcustomerbymobilenumber({ commit }, data) {
    customerbymobile.phone = data.userCreditWallet_phonenumber;
    return axios
      .post(`${mainURL}customer/customer_by_mobile`, customerbymobile, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSNOTIFICTN", response);
        return response.data;
      });
  },
  async getcustreadnotifictn({ commit }, data) {
    custreadnotifctn.user_id = data.user_id;
    custreadnotifctn.notification_id = data.notification_id;
    custreadnotifctn.is_read = data.is_read;
    return axios
      .post(`${mainURL}store/manage_notifications`, custreadnotifctn, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTREADNOTFNT", response);
        return response.data;
      });
  },
  async getadvanceserchlist({ commit }, data) {
    custadvncesearch.search_query = data.search_query;
    custadvncesearch.user_id = data.user_id;
    custadvncesearch.lat = data.lat;
    custadvncesearch.long = data.long;

    return axios
      .post(`${mainURL}customer/advanced_search`, custadvncesearch, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTADVNCESERCH", response);
        return response.data;
      });
  },
  async getadvancefiltrlist({ commit }) {
    return axios
      .post(`${mainURL}customer/get_filter_by_list`, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTFILTRLIST", response);
        return response.data;
      });
  },
  async getadvancenewserchlist({ commit }, data) {
    custadvncenwsearch.search_query = data.search_query;
    custadvncenwsearch.user_id = data.user_id;
    custadvncenwsearch.store_type = data.store_type;
    custadvncenwsearch.delivery_type = data.delivery_type;
    custadvncenwsearch.online_order = data.online_order;
    custadvncenwsearch.lat = data.lat;
    custadvncenwsearch.long = data.long;

    return axios
      .post(`${mainURL}customer/advanced_search`, custadvncenwsearch, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTADVNCENEWSERCH", response);
        return response.data;
      });
  },
  async getstrepagedetail({ commit }, data) {
    // custstrepgedetails.store_id=data.store_id
    // custstrepgedetails.user_id=data.user_id
    let formData = new FormData();
    formData.append("user_id", data.user_id);
    formData.append("store_id", data.store_id);
    formData.append("lat", data.lat);
    formData.append("long", data.long);
    return axios
      .post(`${mainURL}customer/get_store_page_details`, formData, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTSTREPAGEDETL", response);
        return response.data;
      });
  },
  async getdetailcategory({ commit }, data) {
    custdetailcategry.store_id = data.store_id;
    return axios
      .post(`${mainURL}customer/categories_list`, custdetailcategry, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTDETAILCATEGRY", response);
        return response.data;
      });
  },
  async getshopitemdetail({ commit }, data) {
    custitemdetail.store_id = data.store_id;
    custitemdetail.category_id = data.category_id;
    custitemdetail.user_id = data.user_id;
    custitemdetail.sub_category_id = data.sub_category_id;
    custitemdetail.page = data.page;
    custitemdetail.min_price = data.min_price;
    custitemdetail.max_price = data.max_price;
    custitemdetail.min_discount = data.min_discount;
    custitemdetail.max_discount = data.max_discount;
    custitemdetail.brands = data.brands;
    custitemdetail.item_type = data.item_type;
    custitemdetail.prescription = data.prescription;
    custitemdetail.out_of_stock = data.out_of_stock;
    custitemdetail.item_tags = data.item_tags;
    return axios
      .post(
        `${mainURL}customer/category_wise_items_for_khata`,
        custitemdetail,
        {
          headers: {
            "Language-Code": langcode,
            "App-Version": appversion,
            token: apptoken,
          },
        }
      )
      .then((response) => {
        commit("CUSTDETAILITEM", response);
        return response.data;
      });
  },
  async getcatsubcatgryshp({ commit }, data) {
    custcatsubcatgry.store_id = data.store_id;
    custcatsubcatgry.category_id = data.category_id;
    return axios
      .post(`${mainURL}store/sub_categories_list`, custcatsubcatgry, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTSUBCTSHP", response);
        return response.data;
      });
  },
  async getshopcatgryoffrs({ commit }, data) {
    custshopcatgryoffrs.user_id = data.user_id;
    custshopcatgryoffrs.store_id = data.store_id;
    custshopcatgryoffrs.is_store_listing = data.is_store_listing;
    return axios
      .post(`${mainURL}customer/ongoing_store_offers`, custshopcatgryoffrs, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTONGNOFR", response);
        return response.data;
      });
  },
  async getshopsearchitemdetail({ commit }, data) {
    custshopsearch.store_id = data.store_id;
    custshopsearch.user_id = data.user_id;
    custshopsearch.search_query = data.search_query;
    custshopsearch.page = data.page;
    custshopsearch.min_price = data.min_price;
    custshopsearch.max_price = data.max_price;
    custshopsearch.min_discount = data.min_discount;
    custshopsearch.max_discount = data.max_discount;
    custshopsearch.brands = data.brands;
    custshopsearch.item_type = data.item_type;
    custshopsearch.prescription = data.prescription;
    custshopsearch.out_of_stock = data.out_of_stock;
    custshopsearch.categories = data.categories;
    custshopsearch.item_tags = data.item_tags;
    custshopsearch.item_collection_id = data.item_collection_id;
    custshopsearch.limit= data.limit;
    return axios
      .post(
        `${mainURL}customer/item_search`,
        custshopsearch,
        {
          headers: {
            "Language-Code": langcode,
            "App-Version": appversion,
            token: apptoken,
          },
        }
      )
      .then((response) => {
        commit("CUSTSHPITMSERCH", response);
        return response.data;
      });
  },
  async getproductdetail({ commit }, data) {
    custprodctdetail.item_id = data.item_id;
    custprodctdetail.user_id = data.user_id;
    return axios
      .post(`${mainURL}customer/item_details`, custprodctdetail, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTPRODDETAIL", response);
        return response.data;
      });
  },
  async getrecommendeditems({ commit }, data) {
    custrecomitems.item_id = data.item_id;
    custrecomitems.user_id = data.user_id;
    return axios
      .post(`${mainURL}customer/recommended_items`, custrecomitems, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTRECOMITEMS", response);
        return response.data;
      });
  },
  async addtocartproduct({ commit }, data) {
    addcartdetail.user_id = data.user_id;
    addcartdetail.store_id = data.store_id;
    addcartdetail.item_id = data.item_id;
    addcartdetail.item_variants_id = data.item_variants_id;
    addcartdetail.quantity = data.quantity;
    addcartdetail.cart_type = data.cart_type;
    addcartdetail.choices = data.choices;
    addcartdetail.addons = data.addons;
    addcartdetail.combo_items = data.combo_items;
    addcartdetail.add_new = data.add_new;
    addcartdetail.cart_id = data.cart_id;
    return axios
      .post(`${mainURL}customer/manage_cart`, addcartdetail, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTADDTOCART", response);

        // Logout user if token expiry
        if(response.data.is_logout == 1){
          logoutbody.phone = JSON.parse(localStorage.getItem("user")).phone;
          store.dispatch("logout", logoutbody).then(logout_response => {
            if (logout_response.status == "1") {
              bus.$emit("logout", false);
              firebase.auth().signOut().then((user) => {
                console.log("user", user);
              }).catch((error) => {
                console.log(error);
              });
              //window.location.href="/";
              window.location.reload();
            } 
          });
        } else {
          return response.data;
        }

      });
  },
  async usedchoicesaddons({ commit }, data) {
    usedchoicesaddons.user_id = data.user_id;
    usedchoicesaddons.store_id = data.store_id;
    usedchoicesaddons.item_variants_id = data.item_variants_id;
    return axios
      .post(`${mainURL}customer/variants_used_choices_addons`, usedchoicesaddons, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTUSEDCHOICESADDONS", response);
        return response.data;
      });
  },
  async addtocart({ commit }, data) {
    addtocart.user_id = data.user_id;
    addtocart.store_id = data.store_id;
    addtocart.items = data.items;
    return axios
      .post(`${mainURL}customer/add_cart`, addtocart, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("ADDTOCART", response);
        return response.data;
      });
  },
  async getcartdetail({ commit }, data) {
    custcartdetail.user_id = data.user_id;
    custcartdetail.cart_type = data.cart_type;
    custcartdetail.timezone = "+6";
    custcartdetail.offer_id = data.offer_id;
    custcartdetail.offer_discount = data.offer_discount;
    custcartdetail.item_total = data.item_total;

    return axios
      .post(`${mainURL}customer/cart_details`, custcartdetail, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTCARTDETAIL", response);

        // Logout user if token expiry
        if(response.data.is_logout == 1){
          logoutbody.phone = JSON.parse(localStorage.getItem("user")).phone;
          store.dispatch("logout", logoutbody).then(logout_response => {
            if (logout_response.status == "1") {
              bus.$emit("logout", false);
              firebase.auth().signOut().then((user) => {
                console.log("user", user);
              }).catch((error) => {
                console.log(error);
              });
              //window.location.href="/";
              window.location.reload();
            } 
          });
        } else {
          return response.data;
        }

      });
  },
  async getemptycart({ commit }, data) {
    custcartremve.user_id = data.user_id;
    custcartremve.cart_type = data.cart_type;
    return axios
      .post(`${mainURL}customer/empty_cart`, custcartremve, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTCARTEMPTY", response);
        return response.data;
      });
  },
  async getcategrywisestore({ commit }, data) {
    custcatwsestre.user_id = data.user_id;
    custcatwsestre.category_id = data.category_id;
    custcatwsestre.lat = data.lat;
    custcatwsestre.long = data.long;
    custcatwsestre.store_type = data.store_type;
    custcatwsestre.delivery_type = data.delivery_type;
    custcatwsestre.online_order = data.online_order;

    return axios
      .post(
        `${mainURL}customer/store_type_wise_store_details`,
        custcatwsestre,
        {
          headers: {
            "Language-Code": langcode,
            "App-Version": appversion,
            token: apptoken,
          },
        }
      )
      .then((response) => {
        commit("CUSTCATWISEDETL", response);
        return response.data;
      });
  },
  async getcategrywisesearch({ commit }, data) {
    custcatwsesrch.user_id = data.user_id;
    custcatwsesrch.shop_category_id = data.shop_category_id;
    custcatwsesrch.cuisine_id = data.cuisine_id;
    custcatwsesrch.lat = data.lat;
    custcatwsesrch.long = data.long;
    custcatwsesrch.store_type = data.store_type;
    custcatwsesrch.delivery_type = data.delivery_type;
    custcatwsesrch.online_order = data.online_order;
    custcatwsesrch.is_favourite = data.is_favourite;
    custcatwsesrch.near_by = data.near_by;
    custcatwsesrch.area_id = data.area_id;
    custcatwsesrch.pincode = data.pincode;
    return axios
      .post(
        `${mainURL}customer/cuisine_wise_search`,
        custcatwsesrch,
        {
          headers: {
            "Language-Code": langcode,
            "App-Version": appversion,
            token: apptoken,
          },
        }
      )
      .then((response) => {
        commit("CUSTCATWISESEARCH", response);
        return response.data;
      });
  },
  async getremoveitemfrmcart({ commit }, data) {
    custcartremoveitm.user_id = data.user_id;
    custcartremoveitm.store_id = data.store_id;
    custcartremoveitm.item_id = data.item_id;
    custcartremoveitm.item_variants_id = data.item_variants_id;
    custcartremoveitm.cart_type = 1;
    custcartremoveitm.remove_cart = 0;
    custcartremoveitm.cart_id = data.cart_id;
    return axios
      .post(`${mainURL}customer/manage_cart`, custcartremoveitm, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTREMVEITM", response);
        return response.data;
      });
  },
  async getglobalsetng({ commit }) {
    return axios
      .post(`${mainURL}/user/global_settings`, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTGLBLSETNG", response);

        return response.data;
      });
  },
  async getcustorderplaced({ commit }, data) {
    custordrplace.user_id = data.user_id;
    custordrplace.store_id = data.store_id;
    custordrplace.date = data.date;
    custordrplace.items = data.items;
    custordrplace.amount = data.amount;
    custordrplace.remarks = data.remarks;
    custordrplace.order_type = data.order_type;
    custordrplace.payment_method_id = data.payment_method_id;
    custordrplace.is_scheduled = data.is_scheduled;

    return axios
      .post(`${mainURL}customer/manage_customer_orders`, custordrplace, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTPLCEORDR", response);

        return response.data;
      });
  },
  async getcustvalidatecoupon({ commit }, data) {
    validatecoupon.user_id = data.user_id;
    validatecoupon.store_id = data.store_id;
    validatecoupon.discount_code = data.discount_code;
    validatecoupon.amount = data.amount;
    validatecoupon.order_type = data.order_type;
    validatecoupon.payment_method = data.payment_method;
    validatecoupon.items = data.items;
    validatecoupon.delivery_slot = data.delivery_slot;
    
    return axios
      .post(`${mainURL}customer/validate_coupon`, validatecoupon, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTVALCOUPON", response);
        return response.data;
      });
  },
  async generatetransactionrequest({ commit }, data) {
    generatetransactionrequest.user_id = data.user_id;
    generatetransactionrequest.payment_gateway_type = data.payment_gateway_type;
    generatetransactionrequest.amount = data.amount;
    generatetransactionrequest.call_back_url = data.call_back_url;
    generatetransactionrequest.source = data.source;
    generatetransactionrequest.card_number = data.card_number;
    generatetransactionrequest.expiration_date = data.expiration_date;
    generatetransactionrequest.card_code = data.card_code;
    generatetransactionrequest.card_name = data.card_name;
    generatetransactionrequest.billing_address_id = data.billing_address_id;              

    return axios
      .post(`${mainURL}customer/generate_transaction_request`, generatetransactionrequest, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTPLCEDELVRYORDR", response);

        return response.data;
      });
  },
  async generatepaytransaction({ commit }, data) {
    generatetransactionid.user_id = data.user_id;
    generatetransactionid.store_id = data.store_id;
    generatetransactionid.amount = data.amount;
    generatetransactionid.payment_method_id = data.payment_method_id;
    generatetransactionid.source = 1;
    generatetransactionid.call_back_url=data.call_back_url;

    return axios
      .post(`${mainURL}customer/get_transaction_id`, generatetransactionid, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTPLCEDELVRYORDR", response);

        return response.data;
      });
  },
  async createcustomerchat({ commit }, data) {
    createcustomerchat.user_id = data.user_id;
    createcustomerchat.order_id = data.order_id;
    
    return axios
      .post(`${mainURL}customer/order_chat_create`, createcustomerchat, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTPLCEDELVRYORDR", response);

        return response.data;
      });
  },
  async getdelvrycustorderplaced({ commit }, data) {
    let formData = new FormData();
    formData.append("user_id",data.user_id);
    //custordrdelvryplace.user_id = data.user_id;
    formData.append("store_id",data.store_id);
    //custordrdelvryplace.store_id = data.store_id;
    formData.append("date",data.date);
    //custordrdelvryplace.date = data.date;
    formData.append("items",data.items);
    //custordrdelvryplace.items = data.items;
    formData.append("amount",data.amount);
    //custordrdelvryplace.amount = data.amount;
    formData.append("remarks",data.remarks);
    //custordrdelvryplace.remarks = data.remarks;
    formData.append("order_type",data.order_type);
    //custordrdelvryplace.order_type = data.order_type;
    formData.append("payment_method_id",data.payment_method_id);
    //custordrdelvryplace.payment_method_id = data.payment_method_id;
    formData.append("transaction_id",data.transactionid);
    //custordrdelvryplace.transaction_id=data.transactionid;
    formData.append("is_scheduled",data.is_scheduled);
    //custordrdelvryplace.is_scheduled = data.is_scheduled;
    formData.append("delivery_charges",data.delivery_charges);
    //custordrdelvryplace.delivery_charges = data.delivery_charges;
    formData.append("delivery_slot_from",data.delivery_slot_from);
    //custordrdelvryplace.delivery_slot_from = data.delivery_slot_from;
    formData.append("delivery_slot_to",data.delivery_slot_to);
    //custordrdelvryplace.delivery_slot_to = data.delivery_slot_to;
    formData.append("pickup_slot_from",data.pickup_slot_from);
    //custordrdelvryplace.pickup_slot_from = data.pickup_slot_from;
    formData.append("pickup_slot_to",data.pickup_slot_to);
    //custordrdelvryplace.pickup_slot_to = data.pickup_slot_to;
    formData.append("scheduled_delivery_date",data.scheduled_delivery_date);
    //custordrdelvryplace.scheduled_delivery_date = data.scheduled_delivery_date;
    formData.append("offer_code",data.offer_code);
    //custordrdelvryplace.offer_code = data.offer_code;
    formData.append("offer_discount",data.offer_discount);
    //custordrdelvryplace.offer_discount = data.offer_discount;
    formData.append("source",1);
    //custordrdelvryplace.source=1;
    formData.append("packaging_cost",data.packaging_cost);
    //custordrdelvryplace.packaging_cost = data.packaging_cost;
    formData.append("order_tax",data.order_tax);
    //custordrdelvryplace.order_tax = data.order_tax;
    formData.append("pay_thru_wallet",data.pay_thru_wallet);
    //custordrdelvryplace.pay_thru_wallet = data.pay_thru_wallet;
    formData.append("wallet_amount",data.wallet_amount);
    //custordrdelvryplace.wallet_amount = data.wallet_amount;
    formData.append("billing_address_id",data.billing_address_id);

    formData.append("card_name",data.card_name);
    formData.append("card_number",data.card_number);
    formData.append("expiration_date",data.expiration_date);
    formData.append("card_code",data.card_code);
    formData.append("item_total",data.item_total);

    formData.append("customer_profile_id",data.customer_profile_id);
    formData.append("payment_profile_id",data.payment_profile_id);
    formData.append("device_os","web");
    formData.append("signature", data.signature);
    formData.append("id_proof", data.id_proof);
    formData.append("delivery_slot", data.delivery_slot);



    if(data.order_prescription_images.length > 0)
    {
      data.order_prescription_images.forEach(items => {
        formData.append("order_images[]",items);
      })
    }
    return axios
      .post(`${mainURL}customer/manage_customer_orders`, formData, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTPLCEDELVRYORDR", response);

        return response.data;
      });
  },
  async getcustomrreordr({ commit }, data) {
    custmrreordrdelvry.store_id = data.store_id;
    custmrreordrdelvry.order_id = data.order_id;
    custmrreordrdelvry.user_id = data.user_id;
    custmrreordrdelvry.cart_type = data.cart_type;
    custmrreordrdelvry.remove_cart = data.remove_cart;
    return axios
      .post(`${mainURL}customer/reorder`, custmrreordrdelvry, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTREORDER", response);

        return response.data;
      });
  },
  async getcustkhatavieworder({ commit }, data) {
    khataviewdetail.user_id = data.user_id;
    khataviewdetail.order_id = data.order_id;
    return axios
      .post(`${mainURL}customer/view_khata`, khataviewdetail, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTVIEWKHATA", response);

        return response.data;
      });
  },
  async getstorefavorite({ commit }, data) {
    favrtebody.user_id = data.user_id;
    favrtebody.store_id = data.store_id;
    favrtebody.is_favourite = data.is_favourite;

    return axios
      .post(`${mainURL}customer/manage_favourite_stores`, favrtebody, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTFAVRTSTR", response);

        return response.data;
      });
  },
  async getcustnotifyme({ commit }, data) {
    custnotifymebody.user_id = data.user_id;
    custnotifymebody.item_id = data.item_id;
    return axios
      .post(`${mainURL}customer/notify_me`, custnotifymebody, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("NOTIFYMECUST", response);
        return response.data;
      });
  },
  async getstorebusinesshours({ commit }, data) {
    storebusinesshoursbody.store_id = data.store_id;
    return axios
      .post(`${mainURL}customer/store_business_hours`, storebusinesshoursbody, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("STOREBUSINESSHOURS", response);
        return response.data;
      });
  },
  async getcustdeliverycharges({ commit }, data) {
    custdeliverychargesbody.user_id = data.user_id;
    custdeliverychargesbody.store_id = data.store_id;
    custdeliverychargesbody.order_amount = data.order_amount;
    custdeliverychargesbody.lat = data.lat;
    custdeliverychargesbody.long = data.long;
    return axios
      .post(`${mainURL}customer/get_delivery_charges`, custdeliverychargesbody, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTDELIVERYCHARGES", response);
        return response.data;
      });
  },
  async customeraddreview({ commit }, data) {
    addreview.user_id = data.user_id;
    addreview.order_id = data.order_id;
    addreview.store_review = data.store_review;
    addreview.item_review = data.item_review;
    addreview.delivery_review = data.delivery_review;
    return axios
      .post(`${mainURL}customer/add_review`, addreview, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTADDREVIEW", response);
        return response.data;
      });
  },
  async customerwishlist({ commit }, data){
    wishlist.user_id = data.user_id;
    wishlist.page = data.page;
    return axios
      .post(`${mainURL}customer/my_wishlist`,wishlist, {
        headers:{
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTWISHLIST", response);
        return response.data;
      })
  },
  async customerwishlistdetails({ commit }, data){
    getwishlist.wishlist_id = data.wishlist_id
    return axios
      .post(`${mainURL}customer/wishlist_details`,getwishlist, {
        headers:{
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTWISHLISTDETAILS", response);
        return response.data;
      })
  },
  async customeraddtocartfromwishlist({ commit }, data){
    addtocartwishlist.user_id = data.user_id;
    addtocartwishlist.wishlist_id = data.wishlist_id;
    return axios
      .post(`${mainURL}customer/add_cart_from_wishlist`,addtocartwishlist, {
        headers:{
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTADDTOCARTFROMWISHLIST", response);
        return response.data;
      })
  },
  async customermanagewishlist({ commit }, data){
    managewishlist.user_id = data.user_id;
    managewishlist.store_id = data.store_id;
    managewishlist.wishlist_name = data.wishlist_name;
    managewishlist.reminder_type = data.reminder_type;
    managewishlist.dayofweek = data.dayofweek;
    managewishlist.items = data.items;
    managewishlist.daily_time = data.daily_time
    managewishlist.monthly_date = data.monthly_date;
    return axios
      .post(`${mainURL}customer/manage_wishlist`,managewishlist, {
        headers:{
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTMANAGEWISHLIST", response);
        return response.data;
      })
  },
  async customerdeletewishlist({ commit }, data){
    deletewishlist.user_id = data.user_id;
    deletewishlist.wishlist_id = data.wishlist_id;
    return axios
      .post(`${mainURL}customer/delete_wishlist`,deletewishlist, {
        headers:{
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTDELETEWISHLIST", response);
        return response.data;
      })
  },
  async recenetviewdstores({ commit }, data){
    recentstoreview.user_id = data.user_id;
    recentstoreview.lat = data.lat;
    recentstoreview.long = data.long;
    return axios
      .post(`${mainURL}customer/recent_viewed_stores`,recentstoreview, {
        headers:{
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("RECENTVIEWDSTORE", response);
        return response.data;
      })
  },
  async updateFreshChat({ commit }, data){
    freshchat.user_id = data.user_id;
    freshchat.freshchat_restore_id = data.freshchat_restore_id;
    return axios
      .post(`${mainURL}customer/update_fresh_chat`,freshchat, {
        headers:{
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("UPDATEFRESHCHAT", response);
        return response.data;
      })
  },
  async checkUserToken({ commit }, data){
    checkuser.user_id = data.user_id;
    checkuser.token = data.token;
    return axios
      .post(`${mainURL}user/check_user_token`,checkuser, {
        headers:{
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CHECKUSERTOKEN", response);
        return response.data;
      })
  },
  async searchautocomplete({ commit }, data) {
    search_autocomplete.search_query = data.search_query;
    search_autocomplete.store_id = data.store_id;
    search_autocomplete.limit = data.limit;
    search_autocomplete.page = data.page;
    return axios
      .get(`${mainURL}customer/item_suggestions?` + `search_query=${search_autocomplete.search_query}` + `&store_id=${search_autocomplete.store_id}` + `&limit=${search_autocomplete.limit}` + `&page=${search_autocomplete.page}`, {
        headers:{
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("SEARCHAUTOCOMPLETE", response);
        return response.data;
      });
  },
  async savecards({ commit }, data) {
    save_cards.user_id = data.user_id;
    save_cards.page = data.page;
    return axios
      .post(`${mainURL}customer/customer_saved_cards`,save_cards, {
        headers:{
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("SAVECARDS", response);
        return response.data;
      });
  },
  async deletecards({ commit }, data) {
    delete_cards.customer_card_id = data.customer_card_id;
    delete_cards.user_id = data.user_id;
    return axios
      .post(`${mainURL}customer/delete_card`,delete_cards, {
        headers:{
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("DELETECARDS", response);
        return response.data;
      });
  },
  async custaccountstatus({ commit }, data) {
    customer_account.user_id = data.user_id;
    return axios
      .post(`${mainURL}customer/customer_account_status`,customer_account, {
        headers:{
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("CUSTACCOUNTSTATUS", response);
        return response.data;
      });
  },
  async getStoreExclusiveOffer({ commit }, data) {
    store_exclusive_offer.store_id = data.store_id;
    store_exclusive_offer.is_active = data.is_active;
    return axios
      .post(`${mainURL}store/store_exclusive_offers`, store_exclusive_offer, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("GETEXCLUSIVEOFFER", response);
        return response.data;
      });
  },
  async getstorelist({ commit }) {
    return axios
      .get(`${mainURL}customer/store_lists
      `, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("STORELISTS", response);
        return response.data;
      });
  },
  async verifyEmailAddress({ commit }, data) {
    verify_email.user_id = data.user_id;
    verify_email.source = 'web';
    return axios
      .post(`${mainURL}user/verify_email`, verify_email, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("VERIFYEMAIL", response);
        return response.data;
      });
  },
  async emailvarificationotp({ commit }, data) {
    verify_otp.user_id = data.user_id;
    verify_otp.otp = data.otp;
    return axios
      .post(`${mainURL}user/email_verification_otp`, verify_otp, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("EMAILVARIFICATIONOTP", response);
        return response.data;
      });
  },
  async paymentforcustomer({ commit }, data) {
    payment_customer.user_id = data.user_id;
    payment_customer.order_id = data.order_id;
    payment_customer.card_number = data.card_number;
    payment_customer.expiration_date = data.expiration_date;
    payment_customer.card_code = data.card_code;
    payment_customer.card_name = data.card_name;
    return axios
      .post(`${mainURL}customer/payment_for_customer`, payment_customer, {
        headers: {
          "Language-Code": langcode,
          "App-Version": appversion,
          token: apptoken,
        },
      })
      .then((response) => {
        commit("PAYMENTFORCUSTOMER", response);
        return response.data;
      });
  },
};
export default {
  ...actions,
};
