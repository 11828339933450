import { bus } from '../main'
export const func = {
    changeFavicon: (src) => {
        var link = document.createElement('link'),
            oldLink = document.getElementById('dynamic-favicon');
        link.id = 'dynamic-favicon';
        link.rel = 'shortcut icon';
        link.href = src;
        if (oldLink) {
             document.head.removeChild(oldLink);
        }
        document.head.appendChild(link);
    },

    currencySymbol:()=> {
        bus.$on('globalsetting',(data)=>{
            return data.currency_symbol;
        });
    }
}
    