import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import store from './store/store';
import * as VeeValidate from 'vee-validate';
import router from "./routes";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { func } from './store/func'
import VueGeolocation from 'vue-browser-geolocation';
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import { BootstrapVue } from 'bootstrap-vue'
import VueClipboard from 'vue-clipboard2'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueMeta from 'vue-meta'
import vueShapeImg from 'vueshapeimg'
import VueSocialSharing from 'vue-social-sharing'
import VueCardFormat from 'vue-credit-card-validation'
import VueRouterReferer from '@tozd/vue-router-referer';
import ImageMagnifier from 'vue-image-magnifier';
import VueSignature from "vue-signature-pad";
// import './registerServiceWorker'

export const bus = new Vue();
Vue.prototype.$entrallfldrqrd = 'Please enter required field'
Vue.prototype.$entrvalidname = 'Please enter a valid name'
Vue.prototype.$entrvalidemail = 'Please enter a valid email'
Vue.prototype.$entrvalidphone = 'Please enter a valid phone number'
Vue.prototype.$entrvalidamount = 'Please enter a valid amount'
Vue.config.productionTip = false
Vue.use(Vuex);
Vue.use(func);
Vue.use(require('vue-moment'));
Vue.use(VeeValidate,{
  fieldsBagName:'vvFields'
});
Vue.use(VueRouterReferer);
Vue.use(Toast,{ timeout: 3000 });
Vue.use(VueGeolocation);
Vue.use(VueGoogleAutocomplete);
Vue.use(BootstrapVue)
Vue.use(VueSocialSharing)
Vue.use(ImageMagnifier)
VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)
Vue.use(VueCardFormat);
Vue.use(VueSignature);
Vue.config.productionTip = false;
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDU1yuRqsrT2TFdn5srqr5F9iDzdDqzVL4', //'AIzaSyCgrh6LueaEKfpoobObglbr0X6vlXPhouw',
    libraries: 'places',
  }
});
Vue.use(vueShapeImg)
Vue.use(VueMeta)
new Vue({
  store,
  router,
  components:{
    App
  },
  render: h => h(App),
}).$mount('#app')