import Vuex from 'vuex'
import mutations from './mutations'
import getters from './getters'
import actions from './actions'
import state from './state'
import Vue from 'vue'
import firebase from 'firebase/app'
import firebaseconfig from '../firebaseconfig'
import "firebase/analytics";
firebase.initializeApp(firebaseconfig)
export const firebasega = firebase.analytics();

Vue.use(Vuex)
export const store = new Vuex.Store({
    state,
    getters,
    mutations,
    actions,
});
export default store