import 'firebase/auth'
import 'firebase/firestore'
import "firebase/analytics";
 
// GB Demo
// const firebaseConfig = {
//     apiKey: "AIzaSyBbJFiTaEZtKlaudx0uFWXOr3axf2q2IQY",
//     authDomain: "gharbazaartest.firebaseapp.com",
//     projectId: "gharbazaartest",
//     storageBucket: "gharbazaartest.appspot.com",
//     messagingSenderId: "867357975391",
//     appId: "1:867357975391:web:b98269b17e3af31854ccea"
// };

// GB shoptest
// const firebaseConfig = {
//   apiKey: "AIzaSyDxRVYnhZwHpBiY2ftpW6SqG6W25q8MkGU",
//   authDomain: "gb-test-50714.firebaseapp.com",
//   projectId: "gb-test-50714",
//   storageBucket: "gb-test-50714.appspot.com",
//   messagingSenderId: "1064335712",
//   appId: "1:1064335712:web:c6cfb101b357cd252dc605",
//   measurementId: "G-256PRYJ0NH"
// };

// Gharbazaar Live
const firebaseConfig = {
  apiKey: "AIzaSyAVn0gsG-byukkeEEt2_otQMPU5x1_daEs",
  authDomain: "gharbazaar.firebaseapp.com",
  databaseURL: "https://gharbazaar.firebaseio.com",
  projectId: "gharbazaar",
  storageBucket: "gharbazaar.appspot.com",
  messagingSenderId: "1025295582060",
  appId: "1:1025295582060:web:ffe60da02dfc00d8ea2949",
  measurementId: "G-3CLEGJHRQ0"
};

export default firebaseConfig;