const mutations = {
  STOREWISE_DETAILS(state, value) {
    state.storewise_details = value;
  },
  STOREADRESLOCATION_DETAILS(state, value) {
    state.storeadreslocation_details = value;
  },
  LOCATION_DETAILS(state, value) {
    state.location_details = value;
  },
  LOGIN(state, value) {
    state.login = value;
  },
  LOGOUT(state, value) {
    state.logout = value;
  },
  REGISTER(state, value) {
    state.register = value;
  },
  PROFILEDETAILS(state, value) {
    state.profiledetails = value;
  },
  CUSTOMERMANAGEPROFILE(state, value) {
    state.customermanageprofile = value;
  },
  RESETPASSWORD(state, value) {
    state.resetpassword = value;
  },
  VERIFYOTP(state, value) {
    state.verifyotp = value;
  },
  CUSTOMERREGSTRN(state, value) {
    state.customerregstrn = value;
  },
  CUSTOMERADDRESS(state, value) {
    state.customeraddress = value;
  },
  COUNTRIES(state,value){
    state.countries = value
  },
  STATES(state, value) {
    state.states = value;
  },
  CITIES(state, value) {
    state.cities = value;
  },
  AREALIST(state, value) {
    state.arealist = value;
  },
  MANAGECUSTOMERADRES(state, value) {
    state.managecustomeraddrs = value;
  },
  DELETECUSTOMERADRES(state, value) {
    state.deletecustomeraddrs = value;
  },
  KHATACUSTOMER(state, value) {
    state.getcustomerkhata = value;
  },
  KHATADETAIL(state, value) {
    state.getcustomerkhatadetail = value;
  },
  CUSTMRORDER(state, value) {
    state.getcustomerorderlist = value;
  },
  ORDERDETAIL(state, value) {
    state.getcustomerorderdetail = value;
  },
  LATESTORDERDETAIL(state, value) {
    state.getcustomerlatestorder = value;
  },
  CANCELLATIONREASONS(state, value) {
    state.getordercancellationreasons = value;
  },
  ORDERCANCELLATION(state, value) {
    state.customerordercancellation = value;
  },
  CUSNOTIFICTN(state, value) {
    state.getcustomernotification = value;
  },
  CUSTREADNOTFNT(state, value) {
    state.getcustreadnotifictn = value;
  },
  CUSTADVNCESERCH(state, value) {
    state.getadvanceserchlist = value;
  },
  CUSTADVNCENEWSERCH(state, value) {
    state.getadvancenewserchlist = value;
  },
  CUSTFILTRLIST(state, value) {
    state.getadvancefiltrlist = value;
  },
  CUSTSTREPAGEDETL(state, value) {
    state.getstrepagedetail = value;
  },
  CUSTDETAILCATEGRY(state, value) {
    state.getdetailcategory = value;
  },
  CUSTDETAILITEM(state, value) {
    state.getshopitemdetail = value;
  },
  CUSTSUBCTSHP(state, value) {
    state.getcatsubcatgryshp = value;
  },
  CUSTONGNOFR(state, value) {
    state.getshopcatgryoffrs = value;
  },
  CUSTSHPITMSERCH(state, value) {
    state.getshopsearchitemdetail = value;
  },
  CUSTPRODDETAIL(state, value) {
    state.getproductdetail = value;
  },
  CUSTRECOMITEMS(state, value) {
    state.getrecommendeditems = value;
  },
  CUSTADDTOCART(state, value) {
    state.addtocartproduct = value;
  },
  CUSTUSEDCHOICESADDONS(state, value) {
    state.usedchoicesaddons = value;
  },
  ADDTOCART(state, value) {
    state.addtocart = value;
  },
  CUSTCARTDETAIL(state, value) {
    state.getcartdetail = value;
  },
  CUSTCARTEMPTY(state, value) {
    state.getemptycart = value;
  },
  CUSTCATWISEDETL(state, value) {
    state.getcategrywisestore = value;
  },
  CUSTCATWISESEARCH(state, value) {
    state.getcategrywisesearch = value;
  },
  CUSTREMVEITM(state, value) {
    state.getremoveitemfrmcart = value;
  },
  CUSTGLBLSETNG(state, value) {
    state.getglobalsetng = value;
  },
  CUSTPLCEORDR(state, value) {
    state.getcustorderplaced = value;
  },
  CUSTVALCOUPON(state, value) {
    state.getcustvalidatecoupon = value;
  },
  CUSTPLCEDELVRYORDR(state, value) {
    state.getdelvrycustorderplaced = value;
  },
  CUSTVIEWKHATA(state, value) {
    state.getcustkhatavieworder = value;
  },
  CUSTFAVRTSTR(state, value) {
    state.getstorefavorite = value;
  },
  NOTIFYMECUST(state, value) {
    state.getcustnotifyme = value;
  },
  STOREBUSINESSHOURS(state, value) {
    state.getstorebusinesshours = value;
  },
  CUSTDELIVERYCHARGES(state, value) {
    state.getcustdeliverycharges = value;
  },
  CUSTADDREVIEW(state, value) {
    state.customeraddreview = value;
  },
  CUSTWISHLIST(state, value) {
    state.customerwishlist = value;
  },
  CUSTWISHLISTDETAILS(state, value) {
    state.customerwishlistdetails = value;
  },
  CUSTADDTOCARTFROMWISHLIST(state, value) {
    state.customeraddtocartfromwishlist = value;
  },
  CUSTMANAGEWISHLIST(state, value) {
      state.customermanagewishlist = value;
  },
  CUSTDELETEWISHLIST(state, value) {
      state.customerdeletewishlist = value;
  },
  RECENTVIEWDSTORE(state, value) {
    state.recentstoreview = value;
  },
  UPDATEFRESHCHAT(state, value) {
    state.updatefreshchat = value;
  },
  CHECKUSERTOKEN(state, value) {
    state.checkusertoken = value;
  },
  SAVECARDS(state, value){
    state.savecards = value;
  },
  DELETECARDS(state, value){
    state.deletecards = value;
  },
  SEARCHAUTOCOMPLETE(state,value){
    state.deletecards = value;
  },
  CUSTACCOUNTSTATUS(state,value){
    state.custaccountstatus = value;
  },
  GETEXCLUSIVEOFFER(state,value){
    state.getStoreExclusiveOffer = value;
  },
  STORELISTS(state,value){
    state.getstorelist = value;
  },
  VERIFYEMAIL(state,value){
    state.verifyEmailAddress = value;
  },
  EMAILVARIFICATIONOTP(state,value){
    state.emailvarificationotp = value;
  },
  PAYMENTFORCUSTOMER(state,value){
    state.paymentforcustomer = value;
  },
};
export default {
  ...mutations,
};
