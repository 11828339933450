const state = {
  storewise_details: [],
  storeadreslocation_details: [],
  location_details: [],
  login: [],
  logout: [],
  register: [],
  profiledetails: [],
  customermanageprofile: [],
  resetpassword: [],
  verifyotp: [],
  customerregstrn: [],
  states: [],
  cities: [],
  arealist: [],
  managecustomeraddrs: [],
  deletecustomeraddrs: [],
  getcustomerkhata: [],
  getcustomerkhatadetail: [],
  getcustomerorderdetail: [],
  getcustomerlatestorder: [],
  getordercancellationreasons: [],
  customerordercancellation: [],
  getcustomernotification: [],
  getcustreadnotifictn: [],
  getadvanceserchlist: [],
  getadvancefiltrlist: [],
  getadvancenewserchlist: [],
  getstrepagedetail: [],
  getdetailcategory: [],
  getshopitemdetail: [],
  getcatsubcatgryshp: [],
  getshopcatgryoffrs: [],
  getshopsearchitemdetail: [],
  getproductdetail: [],
  getrecommendeditems: [],
  addtocartproduct: [],
  usedchoicesaddons: [],
  addtocart: [],
  getcartdetail: [],
  getemptycart: [],
  getcategrywisestore: [],
  getcategrywisesearch: [],
  getremoveitemfrmcart: [],
  getglobalsetng: [],
  getcustorderplaced: [],
  getcustvalidatecoupon: [],
  getdelvrycustorderplaced: [],
  getcustkhatavieworder: [],
  getstorefavorite: [],
  getcustnotifyme: [],
  getstorebusinesshours: [],
  getcustdeliverycharges: [],
  customeraddreview: [],
  customerwishlist: [],
  customerwishlistdetails: [],
  customeraddtocartfromwishlist: [],
  customermanagewishlist: [],
  customerdeletewishlist: [],
  recentstoreview: [],
  updatefreshchat: [],
  checkusertoken: [],
  searchautocomplete:[],
  savecards:[],
  deletecards:[],
  custaccountstatus:[],
  getStoreExclusiveOffer:[],
  getstorelist:[],
  verifyEmailAddress:[],
  emailvarificationotp: [],
  paymentforcustomer: [],
};
export default {
  ...state,
};
